<template>
  <div>
    <van-nav-bar
      :title="this.$route.query.content ? this.$route.query.content : '标题'"
      @click-left="$goBack()"
      fixed
      left-arrow
      placeholder
    ></van-nav-bar>
    <video
      autoplay
      preload="meta"
      controls
      webkit-playsinline
      playsinline
      x-webkit-airplay="allow"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
    >
    <source :src="this.$route.query.url" type="video/mp4">
    </video>
 
  </div>
</template>
<script>
export default {
  name: "classVideo",

};
</script>
<style lang="less" scoped>
video {
  width: 100%;
  object-fit:fill;
}
</style>